import React, {Component} from 'react';
import {FlatList, SafeAreaView, Button, TextInput, StyleSheet, Text, View} from 'react-native';
import 'react-native-gesture-handler';
import {NavigationContainer} from "@react-navigation/native";
import {createMaterialTopTabNavigator} from "@react-navigation/material-top-tabs";
import DevicesView from "./DevicesView";
import UsersView from "./UsersView";
import ReservationsView from "./ReservationsView";
import {ActivityIndicator} from "react-native-web";
const clone = require('rfdc')();
import styles from "../styles";

class MainView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reservations: [],
            loadedData: [],
            debug: "a",
            isLoading: true,
            loaders: ["devices", "users", "reservations"]
        };
    }

    setLoader = (name) => {
        let state = clone(this.state);
        console.debug(state);
        state.loaders.push(name);
        state.isLoading = true;
        console.debug(state);
        this.setState(state);
    }
    clearLoader = (name) => {
        let state = clone(this.state);

        state.loaders = state.loaders.filter((item) => {return item !== name});
        if (state.loaders.length === 0) {
            state.isLoading = false;
        }
        this.setState(state);
    }

    componentDidMount() {
        this.initiateLoaders();
    }

    initiateLoaders = () => {
        this.dataLoader('devices','https://sahtel.lipso.net/api/device');
        this.dataLoader('users','https://sahtel.lipso.net/api/user');
        this.dataLoader('reservations','https://sahtel.lipso.net/api/reservation');
    }
    dataLoader = (path, url) => {
        if (!this.props.userData) {
            return;
        }
        this.setLoader(path);
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.userData.token,
                'Content-Type': 'application/json'
            },
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 'success') {
                    let loadedData = clone(this.state.loadedData);
                    loadedData[path] = json.data;
                    this.setState({loadedData});
                } else {
                    alert('loading failed: '+url);
                }
                this.clearLoader(path);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    devicesView = () => {
        return <DevicesView userData={this.props.userData} loadedData={this.state.loadedData} dataLoader={this.dataLoader} refresh={this.initiateLoaders} debug={this.state.debug}/>
    }
    usersView = () => {
        return <UsersView userData={this.props.userData} loadedData={this.state.loadedData} dataLoader={this.dataLoader} refresh={this.initiateLoaders}/>
    }
    reservationsView = () => {
        return <ReservationsView userData={this.props.userData} loadedData={this.state.loadedData} dataLoader={this.dataLoader} refresh={this.initiateLoaders}/>
    }
    settingsView = () => {
        return <View>
            <Text>Sahtli Äpp, tere {this.props.userData.name}</Text>
            <Button
                onPress={this.props.onLogout}
                title="Logout"
                color="#841584"
                accessibilityLabel="Logout button"
            />
        </View>

    }

    renderSpinner = () => {
        return (<View>
                <ActivityIndicator size={"large"}/>
            </View>);
    }
    render() {
        if (this.state.isLoading) {
            console.debug('still loading', this.state.loaders);
            return this.renderSpinner();
        }
        console.debug('done loading');
        const Tab = createMaterialTopTabNavigator();
        return (
            <View>
                <Text>
                    Sahtli Äpp, tere {this.props.userData.name}
                    <Button
                        onPress={this.props.onLogout}
                        title="Logout"
                        color="#841584"
                        accessibilityLabel="Logout button"
                    />
                </Text>
                <NavigationContainer>
                    <Tab.Navigator>
                        <Tab.Screen name="Devices" component={this.devicesView} />
                        <Tab.Screen name="Users" component={this.usersView} />
                        <Tab.Screen name="Reservations" component={this.reservationsView} />
                        <Tab.Screen name="Settings" component={this.settingsView} />
                    </Tab.Navigator>
                </NavigationContainer>

            </View>

        );
    }
}

export default MainView;