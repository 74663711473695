import React, {Component} from 'react';
import {FlatList, SafeAreaView, Button, TextInput, StyleSheet, Text, View, Pressable} from 'react-native';
import 'react-native-gesture-handler';
import styles from "../styles";

class UsersView extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    renderUser = (user) => {
        return (
            <View style={styles.deviceElement}>
                <Text>
                    <b>{user.item.code}</b> name:{user.item.name}
                    <Pressable
                        onPress={() => this.selectUser(user.item)}
                    >
                        <Text style={styles.actionButton}>
                            Edit
                        </Text>
                    </Pressable>
                </Text>
            </View>
        );
    }


    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    data={this.props.loadedData['users']}
                    renderItem={this.renderUser}
                    keyExtractor={item => item.id.toString()}
                />
            </View>

        );
    }
}

export default UsersView;