import {StatusBar} from 'expo-status-bar';
import React, {Component} from 'react';
import {Button, TextInput, StyleSheet, Text, View} from 'react-native';

class LoginView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            login: {
                email: "",
                password: ""
            }
        };
    }

    handleUsername = (username) => {
        this.setState({
            login: {
                email: username,
                password: this.state.login.password
            }
        });
    }
    handlePassword = (password) => {
        this.setState({
            login: {
                email: this.state.login.email,
                password: password
            }
        });
    }
    onLogin = () => {
        fetch('https://sahtel.lipso.net/api/auth', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.login)
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 'success' && json.data.type === 'admin') {
                    this.props.setUserData(json.data)
                } else {
                    alert('login failed');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    render() {
        return (
            <View style={styles.container}>
                <Text>Sahtli Äpp, login</Text>
                <TextInput
                    style={{height: 40}}
                    placeholder="Username"
                    onChangeText={this.handleUsername}
                />
                <TextInput
                    style={{height: 40}}
                    placeholder="Password"
                    type={"password"}
                    onChangeText={this.handlePassword}
                />
                <Button
                    onPress={this.onLogin}
                    title="Login"
                    color="#841584"
                    accessibilityLabel="Login button"
                />
                <StatusBar style="auto"/>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default LoginView;