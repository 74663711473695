import 'react-native-gesture-handler';
import {NavigationContainer} from "@react-navigation/native";
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import React, {useEffect, useState, Component} from 'react';
import {LogBox, Button, TextInput, StyleSheet, Text, View, TouchableNativeFeedback} from 'react-native';
import LoginView from "./views/LoginView";
import MainView from "./views/MainView";

class App extends Component {

  constructor(props) {
    super(props);

    let storedUserData = window.localStorage.getItem('sahtelAdminData');
    let userData = null;
    console.debug(storedUserData);
    if (!!storedUserData) {
      userData = JSON.parse(storedUserData);
    }

    this.state = {
      user: userData,
      login: {
        email: "",
        password: ""
      }
    };
  }

  setUserData = (userData) => {
    this.setState({user: userData});
    if (userData) {
      window.localStorage.setItem('sahtelAdminData', JSON.stringify(userData));
    } else {
      window.localStorage.removeItem('sahtelAdminData');
    }
  }

  onLogout = () => {
    window.localStorage.removeItem('sahtelAdminData');
    this.setState({user: null});
  }

  setOpen = (open) => {
    console.debug(open);
    this.setState({open});
    console.debug(this.state);

  }

  render() {

    if (this.state.user) {
      return <MainView
          userData={this.state.user}
          onLogout={this.onLogout}
      />
    } else {
      return <LoginView setUserData={this.setUserData}/>
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default App;