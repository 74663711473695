import React, {Component} from 'react';
import {FlatList, SafeAreaView, Button, TextInput, StyleSheet, Text, View, Pressable} from 'react-native';
import 'react-native-gesture-handler';
import styles from "../styles";

class ReservationsView extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    renderReservation = (reservation) => {
        return (
            <View style={styles.deviceElement}>
                <Text>
                    <b>{reservation.item.device_name}</b> pin:{reservation.item.pin}
                    <Pressable
                        onPress={() => this.selectUser(user.item)}
                    >
                        <Text style={styles.actionButton}>
                            Select
                        </Text>
                    </Pressable>
                    <Pressable
                        onPress={() => this.selectUser(user.item)}
                    >
                        <Text style={styles.actionButton}>
                            Delete
                        </Text>
                    </Pressable>
                    <Pressable
                        onPress={() => this.selectUser(user.item)}
                    >
                        <Text style={styles.actionButton}>
                            Open
                        </Text>
                    </Pressable>
                </Text>
            </View>
        );
    }

    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    data={this.props.loadedData['reservations']}
                    renderItem={this.renderReservation}
                    keyExtractor={item => item.id.toString()}
                />
            </View>

        );
    }
}

export default ReservationsView;