import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    modalContents: {
        height: '100%',
        margin: 12
    },
    modalView: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
        backgroundColor: 'lightgray',
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    modalHeader: {
        height: 40,
        padding: 5,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: 'lightgray'
    },
    modalFooter: {
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        height: 40,
        padding: 5,
        width: '100%',
        backgroundColor: 'lightgray',
        flexDirection: "row"
    },
    modalBody: {
        backgroundColor: "white",
    },
    actionButton: {
        backgroundColor: 'green',
        borderWidth: 2,
        borderRadius: 5,
        marginRight: 5,
        marginLeft: 5,
        padding: 5,
        alignSelf: 'flex-end'
    },
    container: {
        height: '100%',
        backgroundColor: '#fff',
        padding: 5
    },

    deviceElement: {
        backgroundColor: 'white',
        borderWidth: 2,
        borderRadius: 5,
        marginBottom: 5,
        padding: 5
    },
});

export default styles;