import React, {Component} from 'react';
import {Modal, Pressable, FlatList, SafeAreaView, Button, TextInput, StyleSheet, Text, View} from 'react-native';
import 'react-native-gesture-handler';
import styles from "../styles";

class DevicesView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            selectedUser: null,
            selectedDevice: null
        };
    }

    componentDidMount() {
    }

    showReservationsModal = (device) => {
        this.setState({
            showModal: true,
            selectedDevice: device
        });
    }
    hideReservationsModal = () => {
        this.setState({
            showModal: false,
            selectedUser: null,
            selectedDevice: null
        });
    }
    selectUser = (user) => {
        this.setState({
            selectedUser: user
        });
    }
    saveReservation = () => {
        fetch('https://sahtel.lipso.net/api/reservation', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.userData.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "valid_from": "2021-01-01 12:00:00",
                "valid_until": "2022-01-01 12:00:00",
                owner: this.state.selectedUser.id,
                device: this.state.selectedDevice.id
            })
        })
            .then((response) => response.json())
            .then((json) => {
                this.hideReservationsModal();
                if (json.status === 'success') {
                    alert('reservation added');
                } else {
                    alert('reservation failed');
                }
                this.props.refresh();
            })
            .catch((error) => {
                console.error(error);
            });

    }
    renderDevice = (device) => {
        return (
            <View style={styles.deviceElement}>
                <Text>
                    <b>{device.item.code}</b> name:{device.item.name}
                    <Pressable
                    >
                        <Text style={styles.actionButton}>
                            Edit
                        </Text>
                    </Pressable>
                    <Pressable
                        onPress={() => this.showReservationsModal(device.item)}
                    >
                        <Text style={styles.actionButton}>
                            Add reservation
                        </Text>
                    </Pressable>
                </Text>
            </View>
        );
    }

    renderUser = (user) => {
        return (
            <View style={styles.deviceElement}>
                <Text>
                    <b>{user.item.code}</b> name:{user.item.name}
                    <Pressable
                        onPress={() => this.selectUser(user.item)}
                    >
                        <Text style={styles.actionButton}>
                            Select
                        </Text>
                    </Pressable>
                </Text>
            </View>
        );
    }

    render() {
        console.debug(this.state.selectedUser);
        return (
            <View style={styles.container}>
                <Modal
                    style={styles.container}
                    animationType="slide"
                    transparent={false}
                    visible={this.state.showModal}
                    onRequestClose={() => {
                        Alert.alert("Modal has been closed.");
                        this.hideReservationsModal();
                    }}
                >
                    <View style={styles.modalContents}>
                        <View style={styles.modalView}>
                            <View style={styles.modalHeader}>
                                <Text style={styles.modalText}>Assign reservation</Text>
                            </View>
                            <View style={styles.modalBody}>
                                {this.state.selectedUser
                                    ? <Text>User: {this.state.selectedUser.name} Device: <b>{this.state.selectedDevice.code}</b> {this.state.selectedDevice.name}</Text>
                                    : <FlatList
                                        data={this.props.loadedData['users']}
                                        renderItem={this.renderUser}
                                        keyExtractor={item => item.id.toString()}
                                    />
                                }
                            </View>
                            <View style={styles.modalFooter}>
                                <Pressable
                                    style={[styles.button, styles.buttonClose]}
                                    onPress={this.saveReservation}
                                >
                                    <Text style={styles.textStyle}>Save</Text>
                                </Pressable>
                                <Pressable
                                    style={[styles.button, styles.buttonClose]}
                                    onPress={this.hideReservationsModal}
                                >
                                    <Text style={styles.textStyle}>Cancel</Text>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </Modal>
                <FlatList
                    data={this.props.loadedData['devices']}
                    renderItem={this.renderDevice}
                    keyExtractor={item => item.id.toString()}
                />
            </View>
        );
    }
}

export default DevicesView;